import React from "react"
import Layout from "../../components/calculator/layout"
import SEO from "../../components/seo"
import Sidebar from "../../components/calculator/sidebar"
import Step7 from "../../components/calculator/step-7"

const CalculatorStep7 = (props) => (
  <Layout>
    <SEO title="Calculator Step 7" />
    <div id="wrapper" class="d-flex justify-content-between">
      <Sidebar {...props} />
      <Step7 />
    </div>
  </Layout>
)

export default CalculatorStep7
