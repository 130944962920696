import { Link } from "gatsby"
import React from "react"

const Step7 = () => (
  <div class="mainContentWrapper">
    <div
      class="back d-flex align-items-center"
    >
      <svg
        width="7"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 8.75L1.75 5L5.5 1.25"
          stroke="#C4C4C4"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p class="mb-0 ml-3">Back</p>
    </div>
    <div class="row dashboardCardRow">
      <div class="col-md-10 col-lg-8 d-flex align-items-center ">
        <div class="dashboard-content">
          <figure class="my-5">
            <svg
              width="117"
              height="112"
              viewBox="0 0 117 112"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M85.8641 67.672C85.5162 66.7543 84.4516 66.2786 83.4864 66.6092C82.5209 66.9399 82.0204 67.9519 82.3683 68.8694C82.3769 68.8919 82.386 68.9142 82.3955 68.9365C84.09 73.1397 84.2126 76.3539 82.7299 77.7667C80.9314 79.4727 76.4518 78.9394 70.7441 76.3433C63.1027 72.5719 56.1568 67.645 50.1781 61.7557C35.6245 47.9205 29.6195 34.3449 33.3336 30.8145C34.5188 29.9249 36.0593 29.5832 37.5364 29.8821C38.5597 29.9536 39.4503 29.2229 39.5256 28.2502C39.5983 27.31 38.8813 26.4813 37.8968 26.3676C35.3135 25.9332 32.6631 26.6491 30.7064 28.3103C23.8988 34.7793 34.3053 51.6539 47.5509 64.2458C53.8265 70.4186 61.1186 75.5779 69.1406 79.5204C70.5934 80.1816 72.0904 80.7509 73.6221 81.2246L60.4211 85.9824C42.7935 79.6122 30.1037 64.758 27.1633 47.0516C27.0042 46.088 26.0533 45.4293 25.0396 45.5805C24.0258 45.7317 23.3328 46.6355 23.4919 47.5991C23.7836 49.3651 24.1738 51.1312 24.6513 52.8584C28.9297 68.2669 40.1986 81.0675 55.4009 87.7873L33.6736 95.602C33.574 95.444 33.4486 95.3022 33.302 95.1817C27.7801 90.9677 24.3154 84.7876 23.7148 78.0811L22.8527 68.1576C22.7849 67.1962 21.9098 66.4691 20.8981 66.5335C20.8789 66.5349 20.8596 66.5362 20.8405 66.5382C19.8177 66.6154 19.0543 67.4664 19.1356 68.4387C19.1358 68.4426 19.1363 68.4468 19.1368 68.4508L20.0137 78.3707C20.6668 85.5393 24.1849 92.1973 29.8443 96.976L14.345 102.553L17.132 91.6814C17.3752 90.7333 16.7639 89.7774 15.7664 89.5462C14.7689 89.3151 13.7633 89.8961 13.5201 90.8443L9.89517 104.973C9.65085 105.92 10.2607 106.876 11.2575 107.108C11.6231 107.193 12.007 107.171 12.3589 107.044L81.1042 82.3196C81.2136 82.2688 81.3169 82.2072 81.4126 82.1359C82.9004 81.9187 84.2797 81.2648 85.3571 80.2657C87.1981 78.5155 88.783 74.9075 85.8641 67.672Z"
                fill="#4ECAFC"
              />
              <path
                d="M83.6039 65.8291C83.2764 64.9204 82.2743 64.4493 81.3658 64.7767C80.4571 65.1042 79.986 66.1063 80.3134 67.0148C80.3215 67.0371 80.33 67.0592 80.339 67.0813C81.934 71.2436 82.0494 74.4265 80.6538 75.8256C78.9609 77.5149 74.7444 76.9868 69.372 74.416C62.1794 70.6813 55.6415 65.8024 50.0139 59.9705C36.3152 46.27 30.6629 32.8265 34.1588 29.3306C35.2744 28.4496 36.7244 28.1112 38.1147 28.4072C39.0779 28.478 39.9163 27.7544 39.9871 26.7912C40.0555 25.8602 39.3807 25.0396 38.454 24.927C36.0225 24.4968 33.5277 25.2057 31.686 26.8507C25.2782 33.2568 35.0735 49.967 47.5411 62.4363C53.448 68.549 60.3118 73.6581 67.8627 77.5622C69.2301 78.2169 70.6392 78.7807 72.0809 79.2498L59.6553 83.9612C43.0631 77.6531 31.1187 62.9435 28.3509 45.4095C28.2012 44.4553 27.3062 43.803 26.352 43.9527C25.3978 44.1025 24.7454 44.9974 24.8952 45.9517C25.1698 47.7005 25.537 49.4494 25.9865 51.1597C30.0136 66.4183 40.6206 79.0942 54.93 85.7485L34.4789 93.4872C34.3851 93.3307 34.2671 93.1903 34.1291 93.071C28.9315 88.898 25.6704 82.7781 25.105 76.1368L24.2936 66.31C24.2297 65.358 23.406 64.6379 22.4538 64.7018C22.4356 64.7031 22.4175 64.7044 22.3996 64.7063C21.4368 64.7829 20.7183 65.6256 20.7948 66.5883C20.795 66.5923 20.7954 66.5964 20.7959 66.6004L21.6213 76.4237C22.2361 83.5225 25.5475 90.1156 30.8745 94.8478L16.2856 100.371L18.9089 89.6048C19.1377 88.6658 18.5624 87.7193 17.6235 87.4904C16.6845 87.2615 15.738 87.8369 15.5091 88.7758L12.0971 102.767C11.8671 103.705 12.4412 104.652 13.3794 104.881C13.7235 104.966 14.0849 104.943 14.4161 104.818L79.1236 80.3341C79.2265 80.2838 79.3238 80.2228 79.4139 80.1522C80.8143 79.9371 82.1126 79.2896 83.1267 78.3002C84.8596 76.5671 86.3513 72.9942 83.6039 65.8291Z"
                fill="#19B8F8"
              />
              <path
                d="M100.108 61.3207C90.6535 57.7815 80.4352 56.7892 70.4768 58.4439L64.2281 59.4932C63.2796 59.6755 62.6585 60.5921 62.8408 61.5407C63.017 62.4571 63.8816 63.0738 64.8052 62.9419L71.0521 61.9014C80.4046 60.3464 90.0016 61.279 98.8799 64.6051C99.0768 64.6748 99.2847 64.7098 99.4937 64.7083C100.46 64.7098 101.244 63.9283 101.245 62.9623C101.247 62.231 100.793 61.5763 100.108 61.3207Z"
                fill="#19B8F8"
              />
              <path
                d="M106.325 34.2261C105.909 33.3538 104.866 32.9835 103.993 33.3989L67.2676 50.8874C66.3956 51.3025 66.0251 52.3459 66.4402 53.2182C66.4402 53.2184 66.4404 53.2186 66.4404 53.2186C66.8555 54.0906 67.8989 54.4612 68.7712 54.046C68.7714 54.046 68.7714 54.0458 68.7716 54.0458L105.498 36.5573C106.37 36.1419 106.74 35.0983 106.325 34.2261Z"
                fill="#19B8F8"
              />
              <path
                d="M54.52 11.8698C54.3277 10.9232 53.4045 10.3118 52.4579 10.5041C51.5114 10.6965 50.8999 11.6197 51.0923 12.5662C51.0964 12.5872 51.1013 12.608 51.1063 12.6288C53.3199 22.5183 52.4076 32.8514 48.4952 42.2001L45.9524 48.3018C45.5849 49.1909 46.0044 50.2098 46.8915 50.5823C47.104 50.6717 47.3325 50.7174 47.5631 50.717C48.269 50.717 48.9056 50.2929 49.1773 49.6414L51.7219 43.5467C55.9137 33.5326 56.8915 22.4636 54.52 11.8698Z"
                fill="#89D9FA"
              />
              <path
                d="M108.238 62.959C105.34 62.959 102.991 65.3079 102.991 68.2055C102.991 71.1032 105.34 73.4521 108.238 73.4521C111.135 73.4521 113.484 71.1032 113.484 68.2055C113.484 65.3079 111.135 62.959 108.238 62.959ZM108.238 69.9544C107.272 69.9544 106.489 69.1713 106.489 68.2055C106.489 67.2397 107.272 66.4567 108.238 66.4567C109.204 66.4567 109.987 67.2397 109.987 68.2055C109.987 69.1713 109.204 69.9544 108.238 69.9544Z"
                fill="#19B8F8"
              />
              <path
                d="M66.2656 13.9902C63.368 13.9902 61.019 16.3392 61.019 19.2368C61.019 22.1344 63.368 24.4833 66.2656 24.4833C69.1632 24.4833 71.5121 22.1344 71.5121 19.2368C71.5121 16.3392 69.1632 13.9902 66.2656 13.9902ZM66.2656 20.9856C65.2998 20.9856 64.5167 20.2026 64.5167 19.2368C64.5167 18.271 65.2998 17.4879 66.2656 17.4879C67.2314 17.4879 68.0144 18.271 68.0144 19.2368C68.0144 20.2026 67.2314 20.9856 66.2656 20.9856Z"
                fill="#19B8F8"
              />
              <path
                d="M50.5255 6.99541C52.4573 6.99541 54.0232 5.42943 54.0232 3.4977C54.0232 1.56597 52.4573 0 50.5255 0C48.5938 0 47.0278 1.56597 47.0278 3.4977C47.0278 5.42943 48.5938 6.99541 50.5255 6.99541Z"
                fill="#19B8F8"
              />
              <path
                d="M99.4935 8.74316C98.5277 8.74316 97.7446 9.52621 97.7446 10.492V12.2409C97.7446 13.2067 98.5277 13.9897 99.4935 13.9897C100.459 13.9897 101.242 13.2067 101.242 12.2409V10.492C101.242 9.52621 100.459 8.74316 99.4935 8.74316Z"
                fill="#9CE3FF"
              />
              <path
                d="M99.4935 0C98.5277 0 97.7446 0.783048 97.7446 1.74885V3.4977C97.7446 4.46351 98.5277 5.24655 99.4935 5.24655C100.459 5.24655 101.242 4.46351 101.242 3.4977V1.74885C101.242 0.783048 100.459 0 99.4935 0Z"
                fill="#9CE3FF"
              />
              <path
                d="M104.74 5.24707H102.992C102.026 5.24707 101.243 6.03012 101.243 6.99592C101.243 7.96172 102.026 8.74477 102.992 8.74477H104.74C105.706 8.74477 106.489 7.96172 106.489 6.99592C106.489 6.03012 105.706 5.24707 104.74 5.24707Z"
                fill="#9CE3FF"
              />
              <path
                d="M95.9957 5.24707H94.2469C93.2811 5.24707 92.498 6.03012 92.498 6.99592C92.498 7.96172 93.2811 8.74477 94.2469 8.74477H95.9957C96.9615 8.74477 97.7446 7.96172 97.7446 6.99592C97.7446 6.03012 96.9615 5.24707 95.9957 5.24707Z"
                fill="#9CE3FF"
              />
              <path
                d="M87.2515 19.2021H78.4705C77.5047 19.2021 76.7217 19.9852 76.7217 20.951V29.732C76.7217 30.6978 77.5047 31.4808 78.4705 31.4808H87.2515C88.2173 31.4808 89.0004 30.6978 89.0004 29.732V20.951C89.0004 19.9852 88.2173 19.2021 87.2515 19.2021ZM85.5027 27.9831H80.2194V22.6998H85.5027V27.9831Z"
                fill="#19B8F8"
              />
              <path
                d="M74.5115 33.8032C73.8694 33.1179 72.8009 33.0619 72.0909 33.6764L54.6023 49.4161C53.8849 50.0623 53.8269 51.1678 54.4729 51.8855C55.1191 52.603 56.2246 52.6609 56.9423 52.0149L74.4308 36.2752C75.1358 35.6148 75.1719 34.5082 74.5115 33.8032Z"
                fill="#19B8F8"
              />
              <path
                d="M106.489 40.2227C105.523 40.2227 104.74 41.0057 104.74 41.9715V43.7204C104.74 44.6862 105.523 45.4692 106.489 45.4692C107.455 45.4692 108.238 44.6862 108.238 43.7204V41.9715C108.238 41.0057 107.455 40.2227 106.489 40.2227Z"
                fill="#19B8F8"
              />
              <path
                d="M113.909 39.9218L112.673 38.6854C111.978 38.0143 110.871 38.0335 110.2 38.7282C109.545 39.4059 109.545 40.4804 110.2 41.1583L111.436 42.3947C112.131 43.0658 113.238 43.0466 113.909 42.3518C114.564 41.6739 114.564 40.5995 113.909 39.9218Z"
                fill="#19B8F8"
              />
              <path
                d="M115.233 33.2285H113.484C112.518 33.2285 111.735 34.0116 111.735 34.9774C111.735 35.9432 112.518 36.7262 113.484 36.7262H115.233C116.199 36.7262 116.982 35.9432 116.982 34.9774C116.982 34.0116 116.199 33.2285 115.233 33.2285Z"
                fill="#19B8F8"
              />
              <path
                d="M113.867 27.5583C113.189 26.9038 112.114 26.9038 111.436 27.5583L110.2 28.7947C109.517 29.4778 109.517 30.5851 110.2 31.268C110.528 31.5957 110.973 31.78 111.436 31.78C111.9 31.78 112.345 31.5955 112.673 31.2676L113.909 30.0311C114.581 29.3364 114.561 28.2292 113.867 27.5583Z"
                fill="#19B8F8"
              />
              <path
                d="M106.489 24.4834C105.523 24.4834 104.74 25.2664 104.74 26.2322V27.9811C104.74 28.9469 105.523 29.7299 106.489 29.7299C107.455 29.7299 108.238 28.9469 108.238 27.9811V26.2322C108.238 25.2664 107.455 24.4834 106.489 24.4834Z"
                fill="#19B8F8"
              />
              <path
                d="M102.778 28.7948L101.541 27.5583C100.847 26.8874 99.7396 26.9065 99.0685 27.6012C98.414 28.2789 98.414 29.3533 99.0685 30.0312L100.305 31.2677C101 31.9388 102.107 31.9195 102.778 31.2248C103.433 30.5469 103.433 29.4725 102.778 28.7948Z"
                fill="#19B8F8"
              />
              <path
                d="M32.9515 7.56226C32.7456 6.92962 32.1985 6.46879 31.5402 6.37304L26.4073 5.62629L24.1181 0.976089C23.6079 0.109752 22.4919 -0.179027 21.6255 0.3312C21.3593 0.487941 21.1374 0.709826 20.9807 0.976089L18.6809 5.62629L13.548 6.37304C12.5921 6.51099 11.929 7.39787 12.067 8.35384C12.1219 8.73443 12.3007 9.08617 12.5757 9.35484L16.2885 12.975L15.4141 18.0869C15.2462 19.038 15.881 19.9452 16.8322 20.1131C17.2152 20.1807 17.6095 20.1188 17.9534 19.9371L22.5441 17.5167L27.1349 19.9301C27.9898 20.3796 29.0472 20.051 29.4967 19.1961C29.6757 18.8557 29.7374 18.4659 29.6725 18.0869L28.798 12.975L32.5126 9.35484C32.9881 8.88942 33.1581 8.19469 32.9515 7.56226ZM25.6921 11.1124C25.2802 11.5136 25.092 12.0916 25.1884 12.6584L25.6221 15.1855L23.3573 13.991C22.8482 13.7237 22.2401 13.7237 21.7309 13.991L19.4574 15.1838L19.8911 12.6567C19.9893 12.092 19.8043 11.5149 19.3962 11.1124L17.5582 9.32336L20.0957 8.95435C20.6654 8.87172 21.158 8.51386 21.4126 7.99773L22.5441 5.70149L23.6774 7.99773C23.9318 8.51342 24.4235 8.87106 24.9925 8.95435L27.5284 9.32336L25.6921 11.1124Z"
                fill="#FFD803"
              />
              <path
                d="M111.074 87.6609H111.067L110.347 86.5801C106.307 80.5384 99.9539 76.4304 92.7866 75.2248C91.8305 75.0884 90.9447 75.7529 90.8083 76.7091C90.6764 77.633 91.2931 78.4975 92.2095 78.6735C97.3791 79.551 102.09 82.1773 105.555 86.1131C102.642 86.6371 100.094 88.3866 98.5596 90.9172C96.6761 94.2905 97.8839 98.5518 101.257 100.435C104.631 102.319 108.892 101.111 110.775 97.7377C111.495 96.3489 111.883 94.8123 111.91 93.2484C113.771 95.9511 113.92 99.4805 112.293 102.33C111.801 103.161 112.075 104.234 112.905 104.727C113.736 105.219 114.809 104.945 115.302 104.115C115.317 104.088 115.332 104.061 115.347 104.034C118.549 98.314 116.663 91.0866 111.074 87.6609ZM107.725 96.0309C106.792 97.7222 104.664 98.3365 102.973 97.4031C101.282 96.4696 100.667 94.3419 101.601 92.6508C101.606 92.6414 101.611 92.6318 101.617 92.6224C102.649 90.9325 104.389 89.7997 106.352 89.5409C106.657 89.5326 106.958 89.6025 107.227 89.7437C108.703 90.5675 108.782 94.1386 107.725 96.0309Z"
                fill="#19B8F8"
              />
            </svg>
          </figure>
          <h1>Give us some details</h1>

          <form class="mt-4 quote-form  ">
            <div class="form-group float-label-control">
              {" "}
              <input
                type="text"
                class="form-control"
                placeholder="Enter Name"
              />
            </div>
            <div class="form-group float-label-control">
              {" "}
              <input
                type="text"
                class="form-control"
                placeholder="Enter Email Address"
              />
            </div>
            <div class="form-group float-label-control">
              {" "}
              <input
                type="text"
                class="form-control"
                placeholder="Enter Phone Number"
              />
            </div>
          </form>

          <div class="text-left">
            <Link to="/calculator/calculator-step-1">
              {" "}
              <button
                // onclick="location.href = 'calculator-step-1.html'; return false;"
                data-abc="true"
                class="btn btn-default waves-effect waves-light next-step"
              >
                Send
              </button>
            </Link>
          </div>
          <p class="mt-4 pric-policy">
            You do not commit to anything when you fill in your information in
            this form. By clicking the "Submit" button, you accept our{" "}
            <span>privacy policy</span>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Step7
